import React, { Fragment, useState, useEffect, Component } from "react"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import FooterContainer from "../components/FooterContainer"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"
import Button from "react-bootstrap/Button"
import Face from "../assets/jimmy_headshot.jpeg"
import Clock from "../assets/clock.png"
import Globe from "../assets/white-globe.jpeg"
import WebinarVideo from "../components/WebinarVideo"
import {isEmpty} from "lodash"

import ResponsiveAppBar from "../components/ResponsiveAppBar"

import NextButton from "../components/NextButton"


const Webinar = props => {
  useEffect(() => {

  })

  const handleSumit = () => {
  	window.location.href = '/setup-a-meeting'
	}

	return (
		<>

			<GlobalFonts />
{/*			<TopBackground src={topbackground} />
			<TopBackground2 src={topbackground2} />*/}
			<ResponsiveAppBar />
			<Hero>
				<Hero1>
					<WebinarVideo />

				</Hero1>
				<Hero2>
					
					<TitleText>Recorded Webinar</TitleText>
						<Hero3>
							<ClockImg src={Clock} />
							<DetailText>48 Min Recording </DetailText>
						</Hero3>
						<Hero3>
							<ClockImg src={Globe}/>
							<DetailText>America & Canada</DetailText>
						</Hero3>
					<DetailText>Learn how you can own and build passive income from vacation real estate</DetailText>
					<ColoredLine/>
					<CTAButton onClick={handleSumit}>Meet A Consultant</CTAButton>
				</Hero2>
			</Hero>
			<FooterDiv>
				<FooterContainer />
			</FooterDiv>
      <NextButton
      title="Own A Cloud Castle"
      link1="/setup-a-meeting"
      link2="https://cloudcastles.onelink.me/GiaO?pid=HomePage&c=Fab&is_retargeting=true&af_android_url=https://play.google.com/store/apps/details?id%3Dcom.cloudcastles.bmarks&af_dp=https://www.cloudcastles.io/desktop/"
      btitle="I'm Ready To Talk"
      btitle1="Download The App"
      />
		</>
	)
}

export default Webinar

const ColoredLine = styled.hr`
            color: white;
            backgroundColor: 'color';
            height: 5px;
            width:150px;
            animation: SubContentAnimations;
					  animation-delay: 0.1s;
					  animation-duration: 3s;
					  animation-fill-mode: forwards;
					  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const Hero = styled.div`
	background:#8bbef3;
  margin:0px;
  max-width: 100%;
  height:100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;

  padding-top:  100px;
  padding-bottom:  100px;

  @media only screen and (max-width: 1100px) and (min-width: 501px) {
    flex-direction: column;
    // margin: -70px 50px 50px -20px;
  }
  @media only screen and (max-width: 500px) and (min-width: 100px) {
    flex-direction: column;
    // margin: -70px 50px 50px -20px;
    max-width: 100%;
  }
`

const Hero1 = styled.div`
  background:#8bbef3;
  display: flex;
// margin: 100px auto 0px 0px;
// padding 100px auto auto 100px;
	flex-direction:column;
  // justify-content: center;
  // align-content: center;
  margin: auto;
  width:70%;
	height:100%;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    width:100%;
  }  

 
`
const Hero2 = styled.div`
  
  
  margin-top:  auto;
  margin-bottom:  auto;
  margin-left:  100px;
  margin-right:  auto;
  width:60%;
  height:100%;
  // padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
    @media only screen and (max-width: 1100px) and (min-width: 801px) {
		margin-left:  160px;
		margin-top:  50px;
  }
      @media only screen and (max-width: 800px) and (min-width: 421px) {
		margin-left:  100px;
		margin-top:  50px;
  }
    @media only screen and (max-width: 420px) and (min-width: 100px) {
		align-items:center;
		margin-top:  0px;
  }
`


const Hero3 = styled.div`
  margin-top:  auto;
  margin-bottom:  auto;
  margin-left:  auto;
  margin-right:  30px;
  display: flex;
  width:100%;
  flex-direction: row;
  // justify-content: center;
  align-items:space-evenly;
`



const TitleText = styled.h1 `
position: relative;
  width: 80%;
  // height: 43.18px;

  font-family: Poppins;
  font-style: bold;
  font-weight: 500;
  font-size: 27.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
	
`
const DetailText = styled.h2`
position: relative;
  width: 100%;
  // height: 43.18px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const DetailText2 = styled.h2`
position: relative;
  width: 439.52px;
  height: 43.18px;
// margin-right: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const TopBackground = styled.img`
	z-index: -6;
	position: absolute;
	left: -24.63%;
	right: 9.75%;
	top: -2.3%;
	bottom: 78.14%;

	background: #8bbef3;
	border-radius: 333px;
	transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const TopBackground2 = styled.img`
	z-index: -6;
	position: absolute;
	left: -14.72%;
	right: 32.8%;
	top: -16.64%;
	bottom: 81.18%;

	background: #8bbef3;
	opacity: 0.05;
	border-radius: 333px;
	transform: matrix(1, -0.1, 0.08, 1, 0, 0);

`
	
const FooterDiv = styled.div`
	// position: relative;
	width: 100%;
	z-index: 3;
	// left: 0px;
	// top: 700px;
`

const UserImg = styled.img`
  margin-left: 0px;
  margin-top: 0px;
  height: 20%;
  width: 20%;
  border-radius: 100%;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  // left: 50px;
  // right: 0px;
  // top: 30px;
  // bottom: 0px;

  background: url(Image.png);
`
const ClockImg = styled.img`
  // margin-left: 0px;
  // display: flex;
  margin-right: 20px;
  height: 5%;
  width: 5%;
   // justify-content:center;
   // align-items:center;
    // margin:auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  // border-radius: 100%;
  // left: 50px;
  // right: 0px;
  // top: 30px;
  // bottom: 0px;

  // background: url(Image.png);
  @media only screen and (max-width: 600px) and (min-width: 100px) {
		  // margin-right: 20px;
		  height: 7%;
		  width: 7%;
  }
`
const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
